import React from "react"
import styled from "styled-components"
import Icon from "./Icon"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { media } from "../styles";

const Container = styled.div`
  table {
      border-spacing: 0;
  }

  small {
    font-weight: normal;
  }

  td, th {
    text-align: center;
    vertical-align: middle;
  }

  tr[data-label="Feature"] {
    border-bottom: 1px solid var(--lightesterGray);
    th, td {
      padding: 10px 0;
    }
  }

  tr[data-label="Category"] {
    background: var(--lightesterGray);
    th {
      padding: 1em 2em;
    }
  }

  .no-feature {
    position: relative;
    height: 20px;
    &:before {
      content: " ";
      position: absolute;
      width: 15px;
      top: calc(50% + 7.5px);
      left: calc(50% - 7.5px);
      height: 2px;
      background: var(--primaryBlue);
    }
  }

  .release-date,
  .fill-cell {
    display: none;
  }

  ${media.tablet`
    .release-date {
      display: block;
      margin-bottom: 1em;
      font-weight: 500;
    }
  `}

  @media (min-width: 641px) {
    .fill-cell {
      display: table-cell;
    }
  }

  @media screen and (max-width: 40em) {
    tr[data-label="Category"] {
      margin-top: 40px;
    }

    .responsiveTable tbody tr {
        border: none;
        padding: 0.25em;
    }

    .responsiveTable td.pivoted {
      text-align: center !important;
    }

    tr[data-label="Feature"] {
      p {
        border-bottom: 1px solid;
        padding-bottom: 1em;
      }
    }
  }
`;

type FeatureTableRowProps = {
  title: CATEGORY,
  firstRow?: boolean
}
const CategorizedTablePartial = ({ title, firstRow }: FeatureTableRowProps) => {
  return (
    <>
      <Tr key={title} data-label="Category">
        <Th>
          <strong>{title}</strong>
        </Th>
        <Th className="fill-cell" />
        <Th className="fill-cell" />
      </Tr>
      {
        comparisonData
          .filter(item => item.category === title)
          .map((item, index) => <TableCell item={item} key={`${item.name}-${index}`} />)
      }
    </>
  )
}
const TableCell = ({ item }: { item: ComparisonItem }) => {
  const mapComparisonValue = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      return (
        <Td>
          {
            value
              ? <Icon color="var(--primaryColor)" name="check" />
              : <span className="no-feature"></span>
            }
        </Td>
      )
    }

    return <Td><div dangerouslySetInnerHTML={{ __html: value }} /></Td>
  }

  return (
    <Tr data-label="Feature">
      <Th>
        <p dangerouslySetInnerHTML={{ __html: item.name }} />
      </Th>

      {mapComparisonValue(item.r3ProContent)}
      {mapComparisonValue(item.gen3ProContent)}
    </Tr>
  );
}

const CompareTableSimple = () => (
  <Container>
    <Table>
      <Thead>
        <Tr>
          <Th></Th>
          <Th>
            <div>Rachio Pro Series</div>
            <div>
              <strong style={{ color: 'var(--primaryColor)' }}>
                <small className="release-date">Released 2022</small>
              </strong>
            </div>
          </Th>
          <Th>
            <div>Rachio consumer unit</div>
            <div>
              <small className="release-date">Released 2019</small>
            </div>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <CategorizedTablePartial title={CATEGORY.BASICS} firstRow={true} />
        <CategorizedTablePartial title={CATEGORY.CONNECTIVITY} />
        <CategorizedTablePartial title={CATEGORY.INSTALLATION} />
        <CategorizedTablePartial title={CATEGORY.TROUBLESHOOTING} />
        <CategorizedTablePartial title={CATEGORY.WARRANTY} />
      </Tbody>
    </Table>
  </Container>

);

export default CompareTableSimple

enum CATEGORY {
  BASICS = 'Basics',
  INSTALLATION = 'Installation',
  CONNECTIVITY = 'Connectivity / IoT Compatability',
  TROUBLESHOOTING = 'Troubleshooting',
  WARRANTY = 'Warranty'
}

type ComparisonItem = {
  name: string,
  r3ProContent: string | boolean,
  gen3ProContent: string | boolean,
  category: CATEGORY
}

const comparisonData: ComparisonItem[]  = [
  {
    name: 'Zone Counts',
    category: CATEGORY.BASICS,
    r3ProContent: '8, 16',
    gen3ProContent: '4, 8, 12, 16'
  },
  {
    name: 'Pump/Master Valves',
    category: CATEGORY.BASICS,
    r3ProContent: '1',
    gen3ProContent: '1'
  },
  {
    name: 'Sensor Ports',
    category: CATEGORY.BASICS,
    r3ProContent: '2',
    gen3ProContent: '2'
  },
  {
    name: 'Flow Monitoring </br> <small>(supports: badger, cst, everydrop, flowmec, toro)(wired)</small>',
    category: CATEGORY.BASICS,
    r3ProContent: true,
    gen3ProContent: false
  },
  {
    name: 'Indoor/Outdoor',
    category: CATEGORY.BASICS,
    r3ProContent: 'Enclosure optional',
    gen3ProContent: false
  },
  {
    name: 'Wi-Fi Support & Bands',
    category: CATEGORY.CONNECTIVITY,
    r3ProContent: '2.4 / 5 GHz',
    gen3ProContent: '2.4 / 5 GHz'
  },
  {
    name: 'Home Integrations',
    category: CATEGORY.CONNECTIVITY,
    r3ProContent: '<small>Alexa, Control 4, IFTTT, Wink, Control 4, Google Assistant, SmartThings, Nexia</small>',
    gen3ProContent: '<small>Alexa, Control 4, IFTTT, Wink, Control 4, Google Assistant, SmartThings, Nexia</small>'
  },
  {
    name: 'Recurring Software Fees',
    category: CATEGORY.CONNECTIVITY,
    r3ProContent: '$0',
    gen3ProContent: '$0'
  },
  {
    name: 'Dirt-Resistant-Faceplate',
    category: CATEGORY.INSTALLATION,
    r3ProContent: true,
    gen3ProContent: false
  },
  {
    name: 'Wire-Ready/Captive Terminal Screws',
    category: CATEGORY.INSTALLATION,
    r3ProContent: true,
    gen3ProContent: false
  },
  {
    name: 'PCBA Engineered for Testing',
    category: CATEGORY.TROUBLESHOOTING,
    r3ProContent: true,
    gen3ProContent: false
  },
  {
    name: 'Volt Ohms Meter Testing',
    category: CATEGORY.TROUBLESHOOTING,
    r3ProContent: true,
    gen3ProContent: false
  },
  {
    name: 'Warranty Years',
    category: CATEGORY.WARRANTY,
    r3ProContent: '4',
    gen3ProContent: '2 </br> <em><small>No Longer Sold in Pro Distribution<small></em>'
  },
]
